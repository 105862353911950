<div class="card__wrapper flex-center flex-column">
  <div *ngIf="isLoading">{{ 'Loading...' | translate}}</div>
  <div class="error" *ngIf="error && error == PurchaseDetailError.InvalidUuid">
    <p><span translate>Failed to load invoice or booking. Please check the provided link or</span>&nbsp;<a
      href="https://fmtcsafety.com/en/contact-2/" target="_blank" translate>contact us</a>.</p>
  </div>

  <div *ngIf="!isLoading && (!error || error != PurchaseDetailError.InvalidUuid)">

    <div class="card__header bg--dark text--light flex-center"
         [style.borderColor]="isConfirmed ? 'var(--success-fg--color)' : 'var(--warning-fg--color)'">
      <app-rounded-icon class="mx-2 my-2"
                        [icon]="isConfirmed ? 'check' : 'watch_later'"
                        [bgColor]="isConfirmed ? 'var(--success-fg--color)' : 'var(--warning-fg--color)'"
      ></app-rounded-icon>

      <div class="my-2 mr-2">
        <h2 class="mb-0">
          <ng-container *ngIf="isConfirmed">
            {{ 'CARD.CONFIRMED.TITLE' | translate }}
          </ng-container>
          <ng-container *ngIf="!isConfirmed">
            {{ 'CARD.APPLICATION.TITLE' | translate }}
          </ng-container>
        </h2>
        <h4 class="mb-0">
          <ng-container *ngIf="isConfirmed">
            {{ 'CARD.CONFIRMED.SUBTITLE' | translate }}
          </ng-container>
          <ng-container *ngIf="!isConfirmed">
            {{ 'CARD.APPLICATION.SUBTITLE' | translate }}
          </ng-container>
        </h4>
      </div>
    </div>

    <div class="card__body bg--light py-3 px-4">
      <p>
        <ng-content></ng-content>
      </p>
    </div>

    <div class="card__footer bg--muted py-3 px-4">
      <div class="booking__details flex-center-vertical">
      <span class="mr-4">
        <div>
          <b *ngIf="isConfirmed">{{ 'CARD.BOOKING' | translate }}</b>
          <b *ngIf="!isConfirmed">{{ 'CARD.REQUEST_NUMBER' | translate }}</b>

        </div>
        <div>{{isLoading ? ('Loading...' | translate) : purchaseDetails.identifier}}</div>
      </span>
        <span class="mr-4">
        <div><b>{{ 'CARD.REFERENCE' | translate }}</b></div>
        <div>{{isLoading ? ('Loading...' | translate) : (purchaseDetails.remark ? purchaseDetails.remark : '&mdash;') }}</div>
      </span>
        <span class="mr-4">
        <div><b>{{ 'CARD.AMOUNT' | translate }}</b></div>
        <div *ngIf="!isLoading">
          {{purchaseDetails.currency_code}}
          {{ (purchaseDetails.amount_inc_tax / 100).toFixed(2) }}
        </div>
        <div *ngIf="isLoading">{{'Loading...' | translate}}</div>
      </span>
      </div>

      <div class="error" *ngIf="error">
        <p>&nbsp;</p>
        <!--    <p *ngIf="error == 'invalidInvoiceStatus'" translate>This invoice is a credit invoice and cannot be fulfilled online.</p>-->
        <p
          *ngIf="error == PurchaseDetailError.InvoiceAlreadyPaid && !purchaseDetails.is_uninvoiced">{{ 'PURCHASE.ERROR_ALREADY_PAID_INVOICE' | translate}}</p>
        <p
          *ngIf="error == PurchaseDetailError.InvoiceAlreadyPaid && purchaseDetails.is_uninvoiced">{{ 'PURCHASE.ERROR_ALREADY_PAID_BOOKING' | translate}}</p>
        <p
          *ngIf="error == PurchaseDetailError.RedirectFailed">Could not connect with payment gateway, please try again later or contact support <!-- TODO: translate me --></p>
        <!--    <p *ngIf="error == 'generateRedirectUriFailed'" translate>Failed to redirect to payment gateway. Please <a href="#" (click)="tryAgain()">try again</a> later or <a href="https://fmtcsafety.com/en/contact-2/" target="_blank">contact us</a>.</p>-->
      </div>

      <div *ngIf="isConfirmed && isInvoiceable && !error">
        <div class="booking__payment-methods my-3">
          <h3>
            <b class="flex-center-vertical">
              <mat-icon class="mr-1">payment</mat-icon>
              <span>{{ 'CARD.PAY' | translate }}</span>
            </b>
          </h3>

          <div *ngIf="!isBanccardAllowed">
            <span>{{ 'CARD.PAYMENT_METHOD' | translate }}</span>
          </div>
          <div class="payment__options mt-3">
            <button *ngIf="this.isIdealAllowed"
                    class="payment__option" (click)="activateMethod(Method.ideal)"
            >
              <img src="assets/payment/ideal.svg" alt="iDEAL">
              <span>{{ 'CARD.IDEAL' | translate }}</span>
            </button>
            <button class="payment__option" *ngIf="isPaypalAllowed"
                    (click)="pay(Method.paypal)"
            >
              <img src="assets/payment/paypal.svg" alt="PayPal">
              <span>{{ 'CARD.PAYPAL' | translate }}</span>
            </button>

            <button class="payment__option" *ngIf="isCreditCardAllowed"
                    (click)="activateMethod(Method.creditcard)"
            >
              <mat-icon>payment</mat-icon>
              <span>{{ 'CARD.CREDIT_CARD' | translate}}</span>
            </button>

            <button class="payment__option" *ngIf="isBanccardAllowed"
                    (click)="startBanccardPayment()"
            >
              <mat-icon>payment</mat-icon>
              <span>{{ 'CARD.CREDIT_CARD' | translate}}</span>
            </button>
          </div>
        </div>

        <span id="payment-ideal" *ngIf="activeMethod == Method.ideal">
            <p>Bank:
                <select [(ngModel)]="idealIssuer">
                    <option value="" disabled="disabled" selected="selected">Choose your bank</option>
                    <option value="{{issuer.code}}"
                            *ngFor="let issuer of idealIssuers">{{ issuer.name }}</option>
                </select>
            </p>
            <p style="padding-top: 15px;">
                <button class="booking-form-button mat-raised-button submit-booking" (click)="pay()"
                        [disabled]="!idealIssuer" [hidden]="!idealIssuer">
                    <span translate>Pay Now</span>
                </button>
            </p>
        </span>

        <span *ngIf="activeMethod == Method.creditcard && ccType != CcType.banccard">
            <p>
                <select [(ngModel)]="ccType" >
                    <option value="" disabled="disabled" selected="selected">Choose your card</option>
                    <option value="{{cardType.code}}"
                            [attr.disabled]="cardType.label ? 'disabled' : null"
                            *ngFor="let cardType of allowedCardTypes">{{ cardType.name }}</option>
                </select>
            </p>
            <p style="padding-top: 15px;">
                <button class="booking-form-button mat-raised-button submit-booking" (click)="pay()" [hidden]="!ccType">
                    <span translate>Pay Now</span>
                </button>
            </p>
        </span>

      </div>
    </div>
  </div>
</div>
