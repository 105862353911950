import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Component({
  templateUrl: './reset-access-code.component.html',
})
export class ResetAccessCodeComponent implements OnInit {
  public isLoading = false;

  public success = false;
  public error: null | string = null;

  private token: null | string = null;
  private userId: null | string = null;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(routeParams => {
      this.token = routeParams.get('token');
      this.userId = routeParams.get('userId');
    });
  }

  public handleSubmit(): void {
    this.isLoading = true;
    this.error = null;

    this.http.post(`${environment.apiURL}/lms/reset-access-code`, {
      token: this.token,
      user_id: this.userId,
    }).toPromise().then(response => {
      this.success = true;
    }).catch(err => {
      if (err?.error?.token === 'invalidToken') {
        this.error = 'Invalid token';
        return;
      }
      this.error = 'An error occured. Please try again in a few minutes';
    }).finally(() => {
      this.isLoading = false;
    });
  }
}
