import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../../../api.service';
import {HttpParams} from '@angular/common/http';

@Component({
  templateUrl: './payment-complete.component.html',
  styleUrls: ['./payment-complete.component.scss']
})
export class PaymentCompleteComponent implements OnInit {

  public result: 'success'|'cancelled'|'pending'|'error' = 'pending';
  public invoiceUuid: string|undefined;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(routeParams => {
      const queryParams = this.route.snapshot.queryParamMap;
      this.init(
        <'success'|'cancelled'|'error'>routeParams.get('result'),
        <string>routeParams.get('invoice-uuid'),
        queryParams
      );
    })

  }

  async init(result: 'success'|'cancelled'|'error', invoiceUuid: string, queryParams: ParamMap)
  {
    this.result = result;
    this.invoiceUuid = invoiceUuid;

    let httpParams = new HttpParams()
    queryParams.keys.forEach(key => {
      httpParams = httpParams.append(key, <string>queryParams.get(key));
    });
    await this.apiService.submitTransactionResult(invoiceUuid, result, httpParams)
  }


}
