import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';

export interface PurchaseDetails {
  identifier: string;
  amount_inc_tax: number;
  currency_code: string;
  remark: string | any;
  is_invoiceable: boolean;
  is_confirmed: boolean;
  locale: string;
  is_uninvoiced: boolean;
  uuid: string;
  unit_id: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  public async getInvoiceDetails(uuid: string): Promise<PurchaseDetails> {
    let out = await this.http.get<PurchaseDetails>(`${this.apiURL}/invoice/${uuid}`).toPromise();
    out.uuid = uuid;
    out.is_uninvoiced = false;
    return out;
  }

  public async getBookingDetails(uuid: string): Promise<PurchaseDetails> {
    let out = await this.http.get<PurchaseDetails>(`${this.apiURL}/booking-for-purchase/${uuid}`).toPromise();
    out.is_uninvoiced = true;

    return out;
  }

  public async purchase(body: object): Promise<string> {
    let resp = await this.http.post<any>(`${this.apiURL}/purchase`, body).toPromise();
    return resp.redirectUrl;
  }

  public async submitTransactionResult(
      invoiceUuid: string,
      result: 'success'|'cancelled'|'pending'|'error',
      queryParams: HttpParams
  ): Promise<void> {
    const url = this.apiURL + '/purchase/' + invoiceUuid + '/result/' + result;

    return this.http.post<void>(url, {}, {params: queryParams}).toPromise();

  }

  handleError(error: string): void {
    console.error(error);
  }
}
