import {Component, Input, OnInit} from "@angular/core";
import {FormControl, Validators} from "@angular/forms";

@Component(
  {
    selector: 'email-input',
    templateUrl: 'email-input.component.html',
  }
)
export default class EmailInputComponent implements OnInit{
  @Input() label: string = '';
  @Input() formCtrl!: FormControl;

  ngOnInit(): void {
    const validators = this.formCtrl.validator ? [this.formCtrl.validator] : [];
    if (!validators.includes(Validators.email)) {
      this.formCtrl.setValidators([...validators, Validators.email]);
    }
  }
}
