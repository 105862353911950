<div class="card__wrapper flex-center flex-column">
  <div>
    <div class="card__body bg--light py-3 px-4">
      <h2 style="text-align: center">Reset Access Code</h2>

      <ng-container *ngIf="this.success">
        <div class="flex-center">
          <app-rounded-icon class="mx-2 my-2" [icon]="'check'" [bgColor]="'var(--success-fg--color)'">
          </app-rounded-icon>
          {{ 'RESET_PASSWORD.SUCCESS' | translate }}
        </div>
      </ng-container>

      <ng-container *ngIf="!this.success">
        <p>
          If you wish to reset your password, click the button below. You'll receive an email containing your new
          password.
        </p>
        <p *ngIf="this.error != null" style="text-align: center; color: darkred">
          {{ this.error }}
        </p>

        <div style="text-align: center">
          <button
            (click)="handleSubmit()"
            [disabled]="isLoading"
            class="mat-raised-button"
            style="font-size: 1.2em"
          >
            {{ isLoading ? "Please wait..." : "Reset access code"}}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
