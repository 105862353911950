<div class="container toolbar__container">
  <div class="toolbar__brand">
    <img src="../assets/logo.png" alt="FMTC logo">
  </div>
  <div class="flex-center">
    <span class="mr-2">
      <app-rounded-icon icon="phone"></app-rounded-icon>
    </span>
    <span>
      <h2 class="mb-0 text--primary b">{{ 'NAVBAR.PHONE' | translate}}</h2>
      <small class="text--muted">{{ 'NAVBAR.SCHEDULE' | translate }}</small>
    </span>
  </div>

</div>
