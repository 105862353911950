<div class="joining-instructions">
  <div class="title">
    <div style="color: #666">Course information</div>
    <div class="training-name">{{ training }}</div>
  </div>

  <div *ngIf="isLoading">{{ 'Loading...' | translate }}</div>

  <div *ngIf="error">
    {{ error }}
  </div>

  <div style="margin-top: 24px" *ngIf="!error && !isLoading">
    <div>
      <h3>Joining Instructions:</h3>

      <markdown *ngIf="joiningInstructions" [data]="joiningInstructions"></markdown>

      <div *ngIf="!joiningInstructions" style="font-style: italic; font-size: 1.2em">
        No joining instructions have been specified for this course.
      </div>
    </div>

    <div style="margin-top: 24px">
      <h3>Trainee Requirements:</h3>
      <markdown *ngIf="traineeRequirements" [data]="traineeRequirements"></markdown>

      <span *ngIf="!traineeRequirements" style="font-style: italic; font-size: 1.2em">
        No trainee requirements have been specified for this course.
      </span>
    </div>
  </div>
</div>
