import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSliderModule} from '@angular/material/slider';

const commonModules = [
  MatToolbarModule,
  MatButtonToggleModule,
  MatIconModule,
  MatGridListModule,
  MatSliderModule];

@NgModule({
  declarations: [],
  imports: [
    ...commonModules,
    CommonModule
  ],
  exports: [...commonModules]
})
export class MaterialModule {
}
