import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService, PurchaseDetails} from '../../../api.service';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {PurchaseDetailError} from "../../components/card/card.component";

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  isConfirmed = false;
  isInvoiceable = false;
  notFound = false;
  isLoading = true;
  error: PurchaseDetailError | undefined;
  purchaseDetails: PurchaseDetails = {} as PurchaseDetails;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(routeParams => {
      this.init(routeParams.get('uuid'));
    })
  }

  async init(uuid: string | null): Promise<void> {
    await this.setIsConfirmed(window.location.pathname.includes('confirmed'));
    if (!uuid) {
      this.error = PurchaseDetailError.InvalidUuid;
      return;
    }

    if (uuid.startsWith("booking-")) {
      await this.loadBooking(uuid.substring("booking-".length));
    } else {
      this.loadInvoice(uuid);
    }

  }

  async loadBooking(uuid: string): Promise<void> {
    try {
      this.purchaseDetails = await this.apiService.getBookingDetails(uuid);
      this.isInvoiceable = this.purchaseDetails.is_invoiceable;

      this.translate.use(this.purchaseDetails.locale);

      if (this.purchaseDetails.amount_inc_tax <= 0) {
        this.error = PurchaseDetailError.InvoiceAlreadyPaid;
      }

      console.log(this.error);
    } catch (e) {
      this.error = PurchaseDetailError.InvalidUuid
      this.setIsConfirmed(false);
    } finally {
      this.isLoading = false;
    }

  }

  async loadInvoice(uuid: string): Promise<void> {
    try {
      this.purchaseDetails = await this.apiService.getInvoiceDetails(uuid);
      this.isInvoiceable = true;
      this.isLoading = false;
    } catch (e) {
      this.error = PurchaseDetailError.InvalidUuid;
      this.setIsConfirmed(false);
    } finally {
      this.isLoading = false;
    }

  }

  async setIsConfirmed(isConfirmed: boolean): Promise<void> {
    this.isConfirmed = isConfirmed;
    if (isConfirmed) {
      this.titleService.setTitle(await this.translate.get('CARD.CONFIRMED.BROWSER_TITLE').toPromise());
    } else {
      this.titleService.setTitle(await this.translate.get('CARD.APPLICATION.BROWSER_TITLE').toPromise());
    }

  }


}
