<div class="wrapper">
  <div *ngIf="isLoading">
    Loading...
  </div>

  <div *ngIf="alreadySubmitted" class="bg--light p-3">
    <h3 style="text-align: center">You've already updated your information!</h3>
  </div>

  <div *ngIf="!isLoading && submitted" class="bg--light p-3">
    <h3 style="text-align: center; color: #279915; font-weight: 700">Your information has been updated
      successfully!</h3>
    <p style="text-align: center">Thank you for taking the time to review and update your details. See you soon!</p>
  </div>

  <div *ngIf="!isLoading && !submitted && !alreadySubmitted">
    <div class="page-title">
      <h1>Pre-Registration</h1>
    </div>
    <div class="page-content" style="width: 100%">
      <div *ngIf="error" class="error-message">
        <mat-icon>error</mat-icon>
        <span>{{ error }}</span>
      </div>
      <form [formGroup]="form">

        <text-input label="First Name & Middle Name(s)" [formCtrl]="formControl(form, 'firstName')"></text-input>
        <text-input label="Last name" [formCtrl]="formControl(form, 'lastName')"></text-input>

        <div style="margin-bottom: 1em">
          <mat-form-field style="width: 100%">
            <label>Date of birth</label>

            <div style="position: relative">
              <input matInput
                     name="dateOfBirth"
                     #datePicker
                     [matDatepicker]="picker"
                     readonly
                     [formControl]="formControl(form, 'dateOfBirth')"
                     style="width: 100% !important;"
              >

              <mat-datepicker-toggle
                matSuffix
                [disableRipple]="true"
                [for]="picker">
              </mat-datepicker-toggle>
            </div>

            <mat-datepicker #picker></mat-datepicker>
            <mat-hint>Format: M/D/YYYY</mat-hint>
            <mat-error *ngIf="formControl(form, 'dateOfBirth').hasError('required')">This field is required!</mat-error>

          </mat-form-field>
        </div>

        <text-input label="Place of birth" [formCtrl]="formControl(form, 'placeOfBirth')"></text-input>

        <mat-form-field>
          <label>Country of birth</label>
          <mat-select [formControl]="formControl(form, 'birthCountry')">
            <mat-option *ngFor="let country of countryList" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <email-input label="Email address" [formCtrl]="formControl(form, 'email')"></email-input>
        <text-input label="Phone number" [formCtrl]="formControl(form, 'phoneNumber')"></text-input>
        <text-input label="Emergency phone number"
                    [formCtrl]="formControl(form, 'emergencyPhoneNumber')"></text-input>

        <div *ngIf="form.contains('windaId')">
          <text-input label="WINDA ID" [formCtrl]="formControl(form, 'windaId')"></text-input>
          <div class="form-help">
            Your WINDA ID is a unique personal identifier that enables training centers to upload your certificates to
            the WINDA system. To create an account or retrieve your ID if you've forgotten it,
            <a target="_blank" href="https://winda.globalwindsafety.org/register">please click here</a>
          </div>
        </div>

        <div *ngIf="hasRequiredDocuments()" class="section">
          <h3>Upload required documents</h3>
          <div class="file-uploads">
            <div *ngIf="form.contains('medicalExamination')">
              <file-input title="Medical examination" [formCtrl]="formControl(form, 'medicalExamination')"></file-input>
            </div>

            <div *ngIf="form.contains('stcwCertificate')">
              <file-input title="STCW Certificate" [formCtrl]="formControl(form, 'stcwCertificate')"></file-input>
            </div>
          </div>
        </div>

        <div style="margin-top: 16px">
          <button type="submit" class="btn -filled -blue -md" (click)="submit()" [disabled]="isSubmitting">
            <span *ngIf="isSubmitting">Please wait...</span>
            <span *ngIf="!isSubmitting">Next</span>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </form>
    </div>

  </div>
</div>
