<div class="card__wrapper flex-center flex-column">
  <div>

    <div *ngIf="this.result == 'success'"
         class="card__header bg--dark text--light flex-center"
         [style.borderColor]="'var(--success-fg--color)'">
      <app-rounded-icon class="mx-2 my-2"
                        [icon]="'check'"
                        [bgColor]="'var(--success-fg--color)'"
      ></app-rounded-icon>
      <div class="my-2 mr-2">
        <h2 class="mb-0">{{ 'PAYMENT_COMPLETE.HEADER.SUCCESS' | translate }}</h2>
      </div>
    </div>

    <div *ngIf="this.result == 'pending'"
         class="card__header bg--dark text--light flex-center"
         [style.borderColor]="'var(--success-fg--color)'">
      <app-rounded-icon class="mx-2 my-2"
                        [icon]="'watch_later'"
                        [bgColor]="'var(--warning-fg--color)'"
      ></app-rounded-icon>
      <div class="my-2 mr-2">
        <h2 class="mb-0">{{ 'PAYMENT_COMPLETE.HEADER.PENDING' | translate }}</h2>
      </div>
    </div>

    <div *ngIf="this.result == 'cancelled' || this.result == 'error'"
         class="card__header bg--dark text--light flex-center"
         [style.borderColor]="'var(--error-fg--color)'">
      <app-rounded-icon class="mx-2 my-2"
                        [icon]="'error'"
                        [bgColor]="'var(--error-fg--color)'"
      ></app-rounded-icon>
      <div class="my-2 mr-2">
        <h2 class="mb-0" *ngIf="result=='cancelled'">{{ 'PAYMENT_COMPLETE.HEADER.CANCELLED' | translate }}</h2>
        <h2 class="mb-0" *ngIf="result=='error'">{{ 'PAYMENT_COMPLETE.HEADER.ERROR' | translate }}</h2>
      </div>
    </div>

    <div class="card__body bg--light py-3 px-4">
      <p *ngIf="this.result == 'success'">
        {{ 'PAYMENT_COMPLETE.MESSAGE.SUCCESS' | translate }}
      </p>
      <p *ngIf="this.result == 'error'">
        {{ 'PAYMENT_COMPLETE.MESSAGE.ERROR' | translate }}
      </p>
      <p *ngIf="this.result == 'pending'">
        {{ 'PAYMENT_COMPLETE.MESSAGE.PENDING' | translate }}
      </p>
      <p *ngIf="this.result == 'cancelled'">
        <a routerLink="/thank-you/confirmed/{{ invoiceUuid }}">{{ 'PAYMENT_COMPLETE.MESSAGE.CANCELLED' | translate }}</a>.
      </p>
    </div>

    <div class="card__footer bg--muted py-3 px-4">

    </div>
  </div>
</div>
