<span *ngIf="hideGlobalLayout">
    <router-outlet></router-outlet>
</span>

<span *ngIf="!hideGlobalLayout">
  <app-toolbar></app-toolbar>

  <div class="container my-4">
    <router-outlet></router-outlet>
  </div>
</span>
