import {Component, OnInit, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {environment} from '../../../../environments/environment';

@Component({
  templateUrl: './new-booking.component.html',

})
export class NewBookingComponent implements OnInit {

  public embedHtml: SafeHtml|string = '';
  public host: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,

) {
    this.host = environment.bookingFormURL;
  }

  ngOnInit(): void {
    this.loadBookingFormJs()

    this.route.paramMap.subscribe(routeParams => {
      const queryParams = this.route.snapshot.queryParamMap;

      this.embedHtml = this.sanitizer.bypassSecurityTrustHtml(
        '<booking-form\n' +
        '  date="' + (queryParams.get('date') || '') + '"\n' +
        '  training="' + (queryParams.get('trainingId') || '') + '"\n' +
        '  locale="' + (queryParams.get('locale') || 'en') + '"\n' +
        '  location="' + (queryParams.get('location') || '') + '"\n' +
        '  host="' + this.host + '">\n' +
        '\n' +
        '</booking-form>'
      );

      console.log(this.embedHtml);
    })

  }

  async loadBookingFormJs()
  {
    let node = document.createElement('script');
    node.src = this.host + '/dist/booking-form.min.js';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

  }

}
