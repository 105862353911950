import {Component, Input, OnInit} from '@angular/core';
import {ApiService, PurchaseDetails} from '../../../api.service';

export enum PurchaseDetailError {
  InvoiceAlreadyPaid =1,
  InvalidUuid,
  RedirectFailed,
}

export enum Method {
  ideal =1,
  paypal,
  creditcard,
}

export enum CcType {
  mastercard = 'mastercard',
  visa = 'visa',
  maestro = 'maestro',
  vpay = 'vpay',
  visaelectron = 'visaelectron',

  cartebancaire = 'cartebancaire',
  cartebleuevisa = 'cartebleuevisa',

  banccard = 'banccard',
}

interface IdealIssuer {
  name: string;
  code: string;
}

type Metadata = {
  issuer?: string;
  payment_token?: string;
  ccType?: string;
}

type CardType = {
  name: string,
  code: CcType,
  label?: boolean,
}

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() isConfirmed = true;
  @Input() isInvoiceable = true;
  @Input() isLoading = true;
  @Input() error: PurchaseDetailError|undefined;
  @Input() purchaseDetails: PurchaseDetails =  {} as PurchaseDetails

  public activeMethod: Method|undefined;
  public idealIssuer = "";
  public ccType = "";
  public allowedCardTypes: CardType[] = [];

  // For BancCard only
  private paymentToken: string|undefined;

  public idealIssuers: IdealIssuer[] = [
    {name: 'ABN AMRO', code: 'ABNANL2A'},
    {name: 'ASN Bank', code: 'ASNBNL21'},
    {name: 'Bunq', code: 'BUNQNL2A'},
    {name: 'Handelsbanken', code: 'HANDNL2A'},
    {name: 'ING', code: 'INGBNL2A'},
    {name: 'Knab', code: 'KNABNL2H'},
    {name: 'Moneyou', code: 'MOYONL21'},
    {name: 'Rabobank', code: 'RABONL2U'},
    {name: 'Revolut', code: 'REVOLT21'},
    {name: 'SNS Bank', code: 'SNSBNL2A'},
    {name: 'SNS Regio Bank', code: 'RBRBNL21'},
    {name: 'Triodos Bank', code: 'TRIONL2U'},
    {name: 'Van Lanschot', code: 'FVLBNL22'},
    {name: 'Handelsbanken', code: 'HANDNL2A'},
  ];

  constructor(
    private apiService: ApiService,
  ) {
  }

  ngOnInit(): void {
    this.addBanccardScript();
    this.allowedCardTypes = this.cardTypes()
  }

  public get isIdealAllowed(): boolean {
    if (!this.purchaseDetails) {
      return false;
    }

    return this.purchaseDetails.currency_code === 'EUR';
  }

  public get isPaypalAllowed(): boolean {
    if (!this.purchaseDetails) {
      return false;
    }

    if( this.purchaseDetails.unit_id == 19786360 || // USA-MA
        this.purchaseDetails.unit_id == 19822078) // Belgium
    {
      return false;
    }

    return true;
  }

  public get isCreditCardAllowed(): boolean {
    return !this.isBanccardAllowed;
  }

  public get isBanccardAllowed(): boolean {
    if (!this.purchaseDetails) {
      return false;
    }

    return this.purchaseDetails.unit_id == 19786360; // USA-MA
  }

  public activateMethod(method: Method) {
    this.activeMethod = method;
  }

  public get PurchaseDetailError(): typeof PurchaseDetailError {
    return PurchaseDetailError;
  }

  public get Method(): typeof Method {
    return Method;
  }

  public get CcType(): typeof CcType {
    return CcType;
  }

  public startBanccardPayment() {
    this.activeMethod = Method.creditcard;
    this.ccType = CcType.banccard;

    (window as any).CollectJS.startPaymentRequest();
  }

  public async pay(method: Method|null = null) {
    method = method ?? this.activeMethod ?? null;

    var metadata: Metadata = {};
    var methodName: string;
    switch(method) {
      case Method.ideal:
        metadata = { 'issuer': this.idealIssuer };
        methodName = 'ideal';
        break;
      case Method.creditcard:
        metadata = { 'ccType': this.ccType };
        if (this.ccType === CcType.banccard) {
          metadata.payment_token = this.paymentToken;
        }
        methodName = 'creditcard';
        break;
      case Method.paypal:
        methodName = 'paypal';
        break;
      default:
        throw new Error('unknown payment method specified: ' + method);
    }

    this.isLoading = true;

    let body = {
      booking_uuid: null as null|string,
      invoice_uuid: this.purchaseDetails.uuid as null|string,
      method: methodName,
      metadata: metadata,
    }

    if (this.purchaseDetails.is_uninvoiced) {
      body = {
        booking_uuid: this.purchaseDetails.uuid,
        invoice_uuid: null,
        method: methodName,
        metadata: metadata,
      }
    }
    try {
      const redirectUrl = await this.apiService.purchase(body);
      this.isLoading = false;
      window.location.href = redirectUrl;
    } catch(e) {
      this.error = PurchaseDetailError.RedirectFailed
      this.isLoading = false;
    }
  }

  private cardTypes(): CardType[] {
    if (this.isBanccardAllowed) {
      // When BancCard is allowed, no other payment types are accepted
      return [{name: 'BancCard', code: CcType.banccard}]
    }

    let types: CardType[] = [
      {name: 'MasterCard', code: CcType.mastercard},
      {name: 'Visa', code: CcType.visa},
      // {name: 'American Express', code: 'Amex'},        // Disabled acc. to buckaroo admin panel

      // {name: "Debit cards:", label: true, code: "debitCardPlaceholder" },
      {name: 'Maestro', code: CcType.maestro},
      {name: 'VPay', code: CcType.vpay},
      {name: 'Visa Electron', code: CcType.visaelectron},      // Not listed in addmin panel, but we assume it goes together with 'Visa' support
      // {name: 'Dankort', code: 'dankort'},              // Disabled acc. to buckaroo admin panel
      // {name: 'CartaSi', code: 'cartasi'},              // not listed at supported payment methods in the Buckaroo admin
    ];

    if (this.purchaseDetails.unit_id == 12) {
      /** France **/
      types = [...[
        {name: 'Carte Bancaire', code: CcType.cartebancaire},
        {name: 'Carte Bleue', code: CcType.cartebleuevisa},
      ], ...types]
    }

    return types;
  }

  private addBanccardScript() {
    const src = 'https://secure.networkmerchants.com/token/Collect.js';
    const tokenizationKey = 'e3yNs8-Wm4QgC-76542H-3ME8Z5';

    const script = document.createElement('script');
    script.src = src;
    script.setAttribute('data-tokenization-key', tokenizationKey);

    document.body.appendChild(script);

    window.addEventListener('load', () => {
      (window as any).CollectJS.configure({
        callback: (response: {token: string}) => {
          this.paymentToken = response.token;
          this.pay();
        }
      });
    })
  }
}
