import {Component, Input} from "@angular/core";
import {FormControl} from "@angular/forms";

@Component(
  {
    selector: 'text-input',
    templateUrl: 'text-input.component.html',
  }
)
export default class TextInputComponent {
  @Input() label: string = '';
  @Input() formCtrl!: FormControl;
}
