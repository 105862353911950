<div>
  <div class="upload-container">
    <input type="file" #fileInput accept=".pdf,.doc,.docx" hidden (change)="onFileSelected($event)"/>

    <div class="title">{{ title }}</div>
    <button mat-button
            color="primary"
            type="button"
            (click)="fileInput.click()"
    >
      <mat-icon>attach_file</mat-icon>
      Choose file
    </button>
  </div>

  <div *ngIf="selectedFile" class="selected-file">
    {{ selectedFile.name }}
  </div>
</div>
