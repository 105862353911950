import {Component, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'joining-instructions',
  templateUrl: './joining-instructions.component.html',
  styleUrls: ['./joining-instructions.component.scss']
})
export class JoiningInstructionsComponent implements OnInit {

  public training: null | string = null;
  public joiningInstructions: null | string = null;
  public traineeRequirements: null | string = null;

  public isLoading: boolean = true;
  public error: null | string = null;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(routeParams => {
      let trainingId = routeParams.get('trainingId') as string;
      this.fetchInstructions(trainingId);
    })
  }

  private fetchInstructions(trainingId: number | string): void {
    this.http.get<{
      training: string,
      joining_instructions: null | string,
      trainee_requirements: null | string,
    }>(`${environment.apiURL}/v2/public/training/${trainingId}/joining-instructions`)
      .toPromise()
      .then((response) => {
        this.joiningInstructions = response.joining_instructions
        this.traineeRequirements = response.trainee_requirements
        this.training = response.training
      }).catch(err => {
      console.error(err);
      this.error = 'An error occured. Please try again in a few minutes';
    }).finally(() => {
      this.isLoading = false;
    })
  }
}
