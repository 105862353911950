import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

export enum TrainingClassification {
  GWO = 'gwo',
  OPITO = 'opito',
  NOGEPA = 'nogepa',
  STCW = 'stcw',
}

interface PreRegistrationData {
  already_submitted?: boolean,
  training_classification: TrainingClassification | null;
  trainee: {
    first_name: string;
    last_name: string;
    date_of_birth: string | null;
    place_of_birth: string | null;
    email: string | null;
    phone_number: string | null;
    emergency_phone_number: string | null;
  }
}

interface PreRegistrationUpdateResponse {
  error?: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class PreRegistrationService {
  private apiUrl = environment.apiURL;

  constructor(private http: HttpClient) {
  }

  public async getBookingTraineeData(token: string, bookingTraineeId: string): Promise<PreRegistrationData> {
    return this.http
      .get<PreRegistrationData>(`${this.apiUrl}/v2/booking/pre-registration/${token}/${bookingTraineeId}`)
      .toPromise();
  }

  public async updateTraineeData(token: string, bookingTraineeId: string, formData: FormData): Promise<PreRegistrationUpdateResponse> {
    return this.http
      .post<PreRegistrationUpdateResponse>(
        `${this.apiUrl}/v2/booking/pre-registration/${token}/${bookingTraineeId}`,
        formData
      )
      .toPromise()
  }
}
