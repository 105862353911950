import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-rounded-icon',
  templateUrl: './rounded-icon.component.html',
  styleUrls: ['./rounded-icon.component.scss']
})
export class RoundedIconComponent implements OnInit {
  @Input() icon = 'help_outline';
  @Input() bgColor = 'var(--dark-bg--color)';
  @Input() iconColor = 'var(--light-fg--color)';


  constructor() {
  }

  ngOnInit(): void {
  }

}
