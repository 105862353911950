import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './components/card/card.component';
import {RoundedIconComponent} from './components/rounded-icon/rounded-icon.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {MaterialModule} from '../material/material.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ThankYouComponent} from './views/thank-you/thank-you.component';
import {ResetAccessCodeComponent} from './views/netdimensions/reset-access-code/reset-access-code.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PaymentCompleteComponent} from "./views/payment-complete/payment-complete.component";
import {RouterModule} from "@angular/router";
import {NewBookingComponent} from "./views/new-booking/new-booking.component";
import {JoiningInstructionsComponent} from "./views/joining-instructions/joining-instructions.component";
import {MarkdownModule} from "ngx-markdown";
import {PreRegistrationComponent} from "./views/pre-registration/pre-registration.component";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {FileInputComponent} from "./components/form/file-input/file-input.component";
import TextInputComponent from "./components/form/text-input/text-input.component";
import EmailInputComponent from "./components/form/email-input/email-input.component";
import {MatSelectModule} from "@angular/material/select";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const commonComponents = [
    CardComponent,
    RoundedIconComponent,
    ToolbarComponent,
    ThankYouComponent,
    JoiningInstructionsComponent,
    PaymentCompleteComponent,
    ResetAccessCodeComponent,
    NewBookingComponent,
    PreRegistrationComponent,
    FileInputComponent,
    TextInputComponent,
    EmailInputComponent,
  ],
  commonModules = [
    MaterialModule,
    FormsModule,
  ];

@NgModule({
  declarations: [
    ...commonComponents,
  ],
  imports: [
    CommonModule,
    ...commonModules,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    MarkdownModule.forRoot(),
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
  ],
  exports: [
    ...commonComponents,
    ...commonModules,
  ],
})
export class ComponentsModule {
}
