import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RoutesRecognized} from '@angular/router';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'booking-center';

  public hideGlobalLayout = false;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    router: Router
  ) {

    router.events.subscribe((event) => {
      if (! ( event instanceof RoutesRecognized)) {
        return;
      }

      let routeConfig = {hideGlobalLayout: false};

      let current: ActivatedRouteSnapshot | null = event.state.root;
      while (current) {
        if (current.data) {
          routeConfig = {...routeConfig, ...current.data};
        }
        current = current.firstChild;
      }

      this.hideGlobalLayout = routeConfig.hideGlobalLayout;
    });
  }

  ngOnInit(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (window.location.pathname === '/') {
      window.location.href = environment.fmtcsafetyURL;
      return;
    }

    this.translate.addLangs(environment.supportedLangs);
    this.translate.setDefaultLang('en_GB');
    this.route.queryParamMap.subscribe(({params}: any) => {
      if (params.locale && environment.supportedLangs.includes(params.locale)) {
        this.translate.use(params.locale);
      }
    });
  }
}
