import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ThankYouComponent} from './shared/views/thank-you/thank-you.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {PaymentCompleteComponent} from "./shared/views/payment-complete/payment-complete.component";
import {NewBookingComponent} from "./shared/views/new-booking/new-booking.component";
import {ResetAccessCodeComponent} from "./shared/views/netdimensions/reset-access-code/reset-access-code.component";
import {JoiningInstructionsComponent} from "./shared/views/joining-instructions/joining-instructions.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'http://www.fmtcsafety.com'
  },
  {
    path: 'lms/reset-access-code/:userId/:token',
    component: ResetAccessCodeComponent,
  },
  {
    path: 'thank-you/confirmed/:uuid',
    component: ThankYouComponent
  },
  {
    path: 'thank-you/request/:uuid',
    component: ThankYouComponent
  },
  {
    path: 'purchase-result/:result/:invoice-uuid',
    component: PaymentCompleteComponent
  },
  {
    path: 'new-booking',
    component: NewBookingComponent,
    data: {
      hideGlobalLayout: true
    }
  },
  {
    path: 'joining-instructions/:trainingId',
    component: JoiningInstructionsComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '*',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
