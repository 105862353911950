interface env {
  supportedLangs: string[],
  production: boolean,
  fmtcsafetyURL: string,
  apiURL: string,
  bookingFormURL: string
}

const baseEnv = {
  supportedLangs: [
    'en_GB',
    'nl_NL',
  ],
};

const thisScript = (<any>document.currentScript)?.src ?? 'https://booking.fmtcsafety.com';

let locationEnv: object;
switch (new URL(thisScript).hostname) {
  case 'localhost': {
    /*
     * For easier debugging in development mode, you can import the following file
     * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
     *
     * This import should be commented out in production mode because it will have a negative impact
     * on performance if an error is thrown.
     */
    // import('zone.js/dist/zone-error');  // Included with Angular CLI.

    locationEnv = {
      production: false,
      fmtcsafetyURL: 'https://www.fmtcsafety.com',
      apiURL: 'http://localhost:4280',
      bookingFormURL: 'http://localhost:4282',
    };
    break;
  }
  case 'booking.fmtcstaging.nl': {
    locationEnv = {
      production: true,
      fmtcsafetyURL: 'https://www.fmtcstaging.nl',
      apiURL: 'https://api.fmtcstaging.nl',
      bookingFormURL: 'https://bookingform.fmtcstaging.nl',
    };
    break;
  }
  default: {
    locationEnv = {
      production: true,
      fmtcsafetyURL: 'https://www.fmtcsafety.com',
      apiURL: 'https://api.fmtcsafety.com',
      bookingFormURL: 'https://bookingform.fmtcsafety.com',
    };
    break;
  }

}

export const environment = <env>{...baseEnv, ...locationEnv};

