import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PreRegistrationService, TrainingClassification} from "../../../service/pre-registration.service";
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material/core";
import * as moment from "moment";
import * as countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  templateUrl: './pre-registration.component.html',
  styleUrls: ['./pre-registration.component.scss']
})
export class PreRegistrationComponent implements OnInit {
  public isLoading = true;
  public form: FormGroup = new FormGroup({});

  public submitted: boolean = false;
  public alreadySubmitted: boolean = false;
  public error: string | null = null;
  public isSubmitting: boolean = false;
  public countryList: {code: string, name: string}[] = [];
  private trainingClassification: TrainingClassification | null = null;

  private token: string = '';
  private bookingTraineeId: string = '';

  constructor(
    private route: ActivatedRoute,
    private preRegistrationService: PreRegistrationService,
  ) {
  }

  ngOnInit(): void {
    countries.registerLocale(enLocale);
    this.setCountryList();

    this.route.paramMap.subscribe(routeParams => {
      this.token = routeParams.get('token') as string;
      this.bookingTraineeId = routeParams.get('bookingTraineeId') as string;
      this.init();
    })

  }

  private init(): void {
    this.preRegistrationService.getBookingTraineeData(this.token, this.bookingTraineeId).then(result => {
      if (result.already_submitted === true) {
        this.isLoading = false;
        this.alreadySubmitted = true;

        return;
      }

      this.trainingClassification = result.training_classification;

      this.initializeForm(result.trainee);
      this.isLoading = false;

    })
  }

  /**
   * Fixe the 'AbstractControl is not assignable to FormControl' error in the template
   */
  public formControl(form: FormGroup, name: string): FormControl {
    return form.get(name) as FormControl;
  }

  public async submit() {
    // if (this.form.invalid) {
    //   ['medicalExamination', 'stcwCertificate'].forEach(fieldName => {
    //     if (this.form.contains('stcwCertificate') && this.form.get('stcwCertificate')?.errors?.required) {
    //       this.error = 'Please upload the required documents!'
    //     }
    //   })
    //
    //   return;
    // }

    const dateOfBirth = moment(this.form.get('dateOfBirth')?.value, "MM-DD-YYYY").format('YYYY-MM-DD');

    const jsonData = {
      first_name: this.form.get('firstName')?.value,
      last_name: this.form.get('lastName')?.value,
      date_of_birth: dateOfBirth,
      place_of_birth: this.form.get('placeOfBirth')?.value,
      birth_country: this.form.get('birthCountry')?.value,
      email: this.form.get('email')?.value,
      phone_number: this.form.get('phoneNumber')?.value,
      emergency_phone_number: this.form.get('emergencyPhoneNumber')?.value,
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(jsonData));

    if (this.form.contains('medicalExamination')) {
      const medicalExamination = this.form.get('medicalExamination')?.value;
      formData.append('medical_examination', medicalExamination);
    }

    if (this.form.contains('stcwCertificate')) {
      const stcwCertificate = this.form.get('stcwCertificate')?.value;
      formData.append('stcw_certificate', stcwCertificate);
    }

    try {
      this.isSubmitting = true;
      this.error = null;

      await this.preRegistrationService.updateTraineeData(this.token, this.bookingTraineeId, formData);
      this.submitted = true;
    } catch (e) {
      this.error = 'An error occurred! Please try again in a few minutes';
    } finally {
      this.isSubmitting = false;
    }

  }

  public hasRequiredDocuments(): boolean {
    // Uploading certificate and examinations are not required at this stage
    return false;

    // return this.trainingClassification === TrainingClassification.STCW ||
    //   this.trainingClassification === TrainingClassification.OPITO
  }

  private initializeForm(trainee: any): void {

    const dateOfBirth = moment(trainee.date_of_birth, 'YYYY-MM-DD').toDate()

    this.form.addControl('firstName', new FormControl(trainee.first_name, [Validators.required]))
    this.form.addControl('lastName', new FormControl(trainee.last_name, [Validators.required]))
    this.form.addControl('dateOfBirth', new FormControl(dateOfBirth, [Validators.required]))
    this.form.addControl('placeOfBirth', new FormControl(trainee.place_of_birth, [Validators.required]))
    this.form.addControl('birthCountry', new FormControl(trainee.birth_country, [Validators.required]))
    this.form.addControl('email', new FormControl(trainee.email, [Validators.required, Validators.email]))
    this.form.addControl('phoneNumber', new FormControl(trainee.phone_number, [Validators.required]))
    this.form.addControl('emergencyPhoneNumber', new FormControl(trainee.emergency_phone_number, [Validators.required]))

    this.applyTrainingClassificationSpecificUpdates();
  }

  private applyTrainingClassificationSpecificUpdates(): void {
    let classification = this.trainingClassification;

    if (classification === TrainingClassification.GWO) {
      this.form.addControl('windaId', new FormControl(null, [Validators.required]));
    }

    if (classification === TrainingClassification.OPITO || classification === TrainingClassification.NOGEPA) {
      // Only lastName and dateOfBirth fields are required
      ['firstName', 'placeOfBirth', 'phoneNumber', 'emergencyPhoneNumber'].forEach(fieldName => {
        let formControl = this.form.get(fieldName);
        formControl?.clearValidators();
        formControl?.updateValueAndValidity();
      });

      this.form.addControl('medicalExamination', new FormControl(null, [Validators.required]))
    }

    if (classification === TrainingClassification.STCW) {
      this.form.addControl('medicalExamination', new FormControl(null, [Validators.required]))
      this.form.addControl('stcwCertificate', new FormControl(null, [Validators.required]))
    }
  }

  private setCountryList() {
    this.countryList = Object.entries(countries.getNames('en', {select: 'official'})).map(([code, name]) => ({
      code,
      name
    }));
  }
}
