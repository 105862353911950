<app-card
  [purchaseDetails]="purchaseDetails"
  [isConfirmed]="isConfirmed"
  [isInvoiceable]="isInvoiceable"
  [isLoading]="isLoading"
  [error]="error"
>
  <div *ngIf="isConfirmed">

    <p>{{ 'PURCHASE.DEAR_CUSTOMER' | translate }}</p><br/>

    <p>{{ 'PURCHASE.THANK_YOU' | translate }}</p><br/>

    <p>{{ 'PURCHASE.BODY' | translate }}</p><br/><br/>

    <p><b>{{ 'PURCHASE.PREFERRED_CLIENT' | translate }}</b></p>
    <p>{{ 'PURCHASE.PREFERRED_CLIENT_EXPLAIN' | translate }}</p>

  </div>

  <div *ngIf="!isConfirmed">
    <p>{{ 'PURCHASE.DEAR_CUSTOMER' | translate }}</p><br/>

    <p>{{ 'PURCHASE.REQUEST.THANK_YOU' | translate }}</p><br/>

    <p>{{ 'PURCHASE.REQUEST.WHATS_NEXT' | translate }}</p>

  </div>
</app-card>
